var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page1" }, [
    _c("div", { staticClass: "content" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "searchWrapper" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { width: "440px" },
              attrs: {
                "label-position": "right",
                "label-width": "100px",
                model: _vm.formInline,
                rules: _vm.rules,
              },
            },
            [
              _c("el-form-item", { attrs: { label: "当前编辑角色" } }, [
                _vm._v(" " + _vm._s(_vm.systemRoleName) + " "),
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "操作菜单" } },
                [
                  _c("el-tree", {
                    ref: "tree",
                    attrs: {
                      data: _vm.data2,
                      "show-checkbox": "",
                      "node-key": "code",
                      "check-strictly": false,
                      props: _vm.defaultProps,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "center", "margin-top": "20px" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "88px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.submitData },
                },
                [_vm._v("提交")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { width: "88px" },
                  attrs: { type: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "title" }, [
      _c("div", { staticClass: "title_icon" }),
      _vm._v(" 添加/修改功能 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }